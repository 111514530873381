import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['interestGroup'];
  interestGroupTarget: HTMLFormElement;

  filterCoupons() {
    const interestGroupId = $(this.interestGroupTarget).find('option:selected').val();
    $.ajax({
      url: '/couponing.js',
      type: 'GET',
      data: { interest_group_id: interestGroupId },
    });
  }
}
