import i18next from 'i18next';

export const setupTranslations = () => {
  i18next.init({
    fallbackLng: 'de',
    lng: 'de',
    initImmediate: false,
    resources: {
      de: {
        translation: window.CityMessenger.translations,
      },
    },
    interpolation: {
      prefix: '%{',
      suffix: '}',
    },
    parseMissingKeyHandler: key => {
      throw Error(`Translation missing: ${key}`);
    },
  });
  window.CityMessenger.i18next = i18next;
};

const t = (...args): string => {
  return window.CityMessenger.i18next.t(...args);
};
export default t;
