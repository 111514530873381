import { throttle } from 'lodash';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form'];
  formTarget: HTMLFormElement;

  initialize() {
    this.submit = throttle(this.submit, 500);
  }

  submit() {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', this.data.get('url'), true);
    xhr.send(new FormData(this.formTarget));
  }
}
