import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['quantity', 'promoCode'];
  promoCodeTarget: HTMLFormElement;
  quantityTarget: HTMLFormElement;
  hasQuantityTarget: boolean;

  validatePromoCode() {
    const promoCode = $(this.promoCodeTarget).val();
    $.ajax({
      url: '/couponing/book_purchases/promo_code_validation',
      type: 'POST',
      data: { couponing_book_purchase: { promo_code: promoCode } },
      complete: ({ responseJSON: { valid, discount } }) => {
        if (valid) {
          this.promoCodeTarget.classList.add('is-valid');
          this.promoCodeTarget.dataset.discount = discount;
        } else {
          this.promoCodeTarget.classList.remove('is-valid');
        }
        this.updatePaymentSummary(valid);
      },
    });
  }

  updateQuantity() {
    const promoCodeValidity = this.promoCodeTarget.classList.contains('is-valid');
    this.updatePaymentSummary(promoCodeValidity);
  }

  private updatePaymentSummary(applyDiscount: boolean) {
    const quantity = this.hasQuantityTarget ? this.quantityTarget.value : 1;
    const discount = this.promoCodeTarget.dataset.discount;
    $.ajax({
      url: '/couponing/book_purchases/new.js',
      type: 'GET',
      data: { quantity, discount: applyDiscount ? discount : null },
    });
  }
}
