import Cropper from 'cropperjs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'canvas', 'input', 'changeset' ];
  canvasTarget: HTMLImageElement;
  inputTarget: HTMLInputElement;
  changesetTarget: HTMLInputElement;
  cropper: Cropper;

  makeEditable() {
    this.canvasTarget.src = URL.createObjectURL(this.inputTarget.files[0]);

    if (this.cropper) {
      this.cropper.replace(this.canvasTarget.src);
    } else {
      this.cropper = new Cropper(this.canvasTarget, {
        viewMode: 2,
        aspectRatio: 1,
        crop: (event) => {
          this.changesetTarget.value = JSON.stringify({ crop: { ...event.detail } });
        },
      });
    }
  }
}
