import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = [ 'text', 'output' ];
  textTarget: HTMLTextAreaElement;
  outputTarget: Element;

  connect() {
    this.update();
  }

  update() {
    this.outputTarget.textContent = `${this.textTarget.textLength} / ${this.textTarget.maxLength}`;
  }
}
