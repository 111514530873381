import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['shop'];
  shopTarget: HTMLFormElement;

  filter() {
    const shopId = $(this.shopTarget).find('option:selected').val();
    $.ajax({
      url: '/statistics.js',
      type: 'GET',
      data: { shop_id: shopId },
    });
  }
}
