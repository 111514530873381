import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['couponBook'];
  couponBookTarget: HTMLFormElement;

  filter() {
    const couponBookId = $(this.couponBookTarget).find('option:selected').val();
    $.ajax({
      url: '/admin/coupons.js',
      type: 'GET',
      data: { coupon_book_id: couponBookId },
    });
  }
}
