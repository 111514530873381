// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

const application = Application.start();
// tslint:disable-next-line:no-string-literal
const context = require['context']('controllers', true, /_controller\.ts$/);
application.load(definitionsFromContext(context));
