import './placeholder_avatar.png'
import './logo-city-messenger.png'
import './app-store.png'
import './play-store.png'
import './skyline-stgallen.svg'
import './logo-swiss-made-software.png'
import './logo-fhs.png'
import './logo-innosuisse.png'
import './logo-pro-city.png'
import './logo-st-gallen.png'
import './login-image.jpg'
import './customer-fallback-avatar.png'
import './smartphone-gray.png'
import './renuo.svg'
import './broadcast-client.png'
import './direct-message-client.png'
import './settings-client.png'
import './blick.png'
import './fm1.png'
import './srf.png'
import './tagblatt.png'
import './toponline.png'
import './tvo.png'
import './shop-overview-client.png'

require.context('./quote-chat-icons', true)
